<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class="container my-5">

        <div class="row" id="printArea">
          <!-- invoice view page -->
          <div class="col-12">
            <div class="bg-white">
              <img src="/app-assets/images/invoice/orderInvoice/orderInvoiceHeader.png" class="img-fluid" alt="order-invoice-header">
            </div>
            <div class="pb-0 container bg-white">

              <div class="row">
                <div class="col-sm-8 col-12 text-center text-sm-left order-2 order-sm-1 pt-4">
                  <h4 class="font-weight-bold" style="color:#F38020;">Invoice: </h4>
                  <table class="table table-borderless mb-0">

                    <tbody>
                    <tr v-if="orderDetails.order_no" class="mb-1">
                      <td class="py-0 pl-0 font-weight-light" style="width: 20%; color: #58595B">Ref :</td>
                      <td class="py-0 pl-0" style="width: 80%"><span class="font-weight-light" style="color: #58595B">#{{
                          orderDetails.order_no
                        }}</span>
                        <span v-if="orderDetails.appointment">
                          <span><i class="bx bx-link" style="font-size: 1.5rem;"></i> </span>
                          <span v-if="orderDetails.appointment.type == 'Home'">
                            <i class="bx bx-home" style="font-size: 1.5rem;"></i>
                          </span>
                            <span v-else><i class="bx bxs-business" style="font-size: 1.5rem;"></i>   </span>
                            <span class="text-secondary text-light">#{{ orderDetails.appointment.reference }}</span>
                          </span>
                      </td>
                    </tr>
                    <tr v-if="orderDetails.delivery_date" class="mb-1">
                      <td class="py-0 pl-0 font-weight-light" style="width: 20%;color: #58595B">Date :</td>
                      <td class="py-0 pl-0" style="width: 80%"><span class="font-weight-light" style="color: #58595B">{{
                          orderDetails.delivery_date
                        }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div
                    class="col-sm-4 col-12 text-center text-sm-right order-1 order-sm-2 d-sm-flex justify-content-end mb-1 mb-sm-0">
                </div>
              </div>

              <div class="row py-1">
                <div class="col-12">
                  <hr>
                </div>
              </div>

              <!-- invoice address and contact -->
              <div class="row invoice-info">
                <div class="col-sm-4 col-12">
                  <h6 class="font-weight-bold">Customer Details</h6>
                  <div v-if="orderDetails?.user">
                    <div class="mb-1">
                      <span style="color:#404041;font-weight:500;">Name : </span>
                      <span
                          style="color:#404041;font-weight:400;">{{
                          `${orderDetails?.user?.first_name ?? ''} ${orderDetails?.user?.last_name ?? ''}`
                        }}</span>
                    </div>
                    <div class="mb-1">
                      <span style="color:#404041;font-weight:500;">Address : </span>
                      <span
                          style="color:#404041;font-weight:400;">{{
                          fullAddress(orderDetails?.user?.customer)
                        }}</span>
                    </div>
                    <div class="mb-1">
                      <span style="color:#404041;font-weight:500;">Email : </span>
                      <span style="color:#404041;font-weight:400;">{{ orderDetails?.user?.email }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4 col-12 mt-1">
                  <h6 class="font-weight-bold">Billing Address</h6>
                  <div class="mb-1" v-if="orderDetails?.billingAddress?.id">
                      <span>{{
                          `${orderDetails.billingAddress.street},
                        ${orderDetails.billingAddress.suburb},
                        ${orderDetails.billingAddress.state},
                        ${orderDetails.billingAddress.post_code},
                        ${orderDetails.billingAddress.country}`
                        }}
                      </span>
                  </div>
                </div>

                <div class="col-sm-4 col-12 mt-1">
                  <h6 class="font-weight-bold">Shipping Address</h6>
                  <div class="mb-1" v-if="orderDetails?.shippingAddress?.id">
                      <span>{{
                          `${orderDetails.shippingAddress.street},
                        ${orderDetails.shippingAddress.suburb},
                        ${orderDetails.shippingAddress.state},
                        ${orderDetails.shippingAddress.post_code},
                        ${orderDetails.shippingAddress.country}`
                        }}
                      </span>
                  </div>

                </div>
              </div>

              <div class="row pt-0">
                <div class="col-12">
                  <hr>
                </div>
              </div>
            </div>

            <div class="container bg-white py-1">
              <div class="row">
                <div class="col-12">
                  <h3 class="text-center font-weight-bold" style="color:#F38020" v-if="orderDetails?.delivery_date">
                    Delivery: {{
                      new Date(`${orderDetails.delivery_date}`).toLocaleDateString("en-AU", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                    }}</h3>
                </div>
              </div>
            </div>

            <!-- product details table-->
            <div class="container bg-white">
              <div class="row">
                <div class="col-12">
                  <div class="invoice-product-details table-responsive">
                    <table class="table table-borderless mb-0">
                      <thead>
                      <tr class="border-0">
                        <th class="text-left text-white table-text-color-background" style="width: 50%;">
                          PRODUCT
                        </th>
                        <th class="text-center text-white table-text-color-background"
                            style="width: 15%;">QTY
                        </th>
                        <th class="text-center text-white table-text-color-background"
                            style="width: 15%;">UNIT PRICE
                        </th>
                        <th class="text-right text-white table-text-color-background"
                            style="width: 20%;">PRICE
                        </th>
                      </tr>
                      </thead>

                      <tbody v-if="orderDetails.details!==undefined && orderDetails.status === 'Pending'">
                      <tr v-for="(cartDetails) in orderDetails.details.cart_data" :key="cartDetails.productId">
                        <td class="text-capitalize" style="color:#000;font-weight:500;">
                          {{ cartDetails.name }} ({{ cartDetails?.product_price?.condition_name }})
                          <div
                              v-if="cartDetails.product_items !== undefined && cartDetails.product_items?.length !== 0">
                            <ul>
                              <div v-for="(singleItem) in cartDetails.product_items" :key="singleItem.id">
                                <li
                                    v-if="singleItem.item_obj.condition === cartDetails.product_price.condition_name">
                                  {{ singleItem.item_obj.serial_number }}
                                </li>

                              </div>
                            </ul>
                          </div>
                        </td>
                        <td class="text-center" style="color:#000;font-weight:500;">
                          {{ cartDetails.product_price.quantity }}
                        </td>
                        <td class="text-center" style="color:#000;font-weight:500;">
                          ${{ parseFloat(cartDetails?.product_price?.amount / 100).toFixed(2) }}
                        </td>
                        <td class="text-right" style="color:#000;font-weight:500;">
                          ${{ parseFloat(cartDetails?.product_price?.sub_total / 100).toFixed(2) }}
                        </td>
                      </tr>
                      </tbody>

                      <tbody v-if="orderDetails.status === 'Processing'">
                      <tr v-for="(key) in customProducts" :key="key">
                        <td class="text-capitalize" style="color:#000;font-weight:500;">
                          <div class="d-flex">
                            <span style="width:70%">{{ key.name }}</span>
                            <span class="ml-auto text-right" style="font-size: 0.8rem; width:30%">{{
                                key.placed_date != null ? new Date(`${key.placed_date}`).toLocaleDateString("en-AU", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }) : ''
                              }}</span>
                          </div>
                          <div v-if="key.productItem !== undefined && key.productItem?.length !== 0">
                            <ul>
                              <div v-for="(singleItem) in key.productItem" :key="singleItem.id">
                                <li>
                                  {{ singleItem.serialNumber }} <span
                                    v-if="singleItem.serialNumber">({{ singleItem.condition }})</span>
                                </li>
                              </div>
                            </ul>
                          </div>
                        </td>
                        <td class="text-center" style="color:#000;font-weight:500;">{{ key.productItem.length }}</td>
                        <td class="text-center" style="color:#000;font-weight:500;">
                          ${{ parseFloat((key.productItem[0].unitPrice) / 100).toFixed(2) }}
                        </td>
                        <td class="text-right" style="color:#000;font-weight:500;">
                          ${{ ((key.productItem[0].unitPrice * key.productItem.length) / 100).toFixed(2) }}
                        </td>
                      </tr>
                      </tbody>

                    </table>
                  </div>
                  <!-- invoice subtotal -->
                  <div class="pt-0 mx-2">
                    <hr>
                    <div style="width:100%; display: grid; grid-template-columns: repeat(2, 1fr)">
                    <div>
                        <span><b>Bank Details:</b></span><br>
                        <span>Geeks CRS.</span><br>
                        <span>Commonwealth Bank</span><br>
                        <span> <b>BSB:</b> 062-692, <b>AC:</b> 4519 8950</span>
                    </div>
                    <div>
                      <table class="table table-borderless ">
                      <tbody>
                      <!-- subtotal -->
                      <tr class="mb-1">
                        <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">SubTotal :</td>
                        <td class="py-0 pl-4 pr-0 " style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(subTotal / 100).toFixed(2) }}</span>
                        </td>
                      </tr>
                      <!-- charges -->
                      <tr class="mb-1" v-for="(charge,index) in orderPayment.chargesDetails" :key="index">
                        <td v-if="index !== 0" class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">
                          <span class="text-capitalize">{{ charge.name }} ({{ charge.sign }}) : </span>
                        </td>
                        <td v-if="index !== 0" class="py-0 pl-4 pr-0 " style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(charge.amount / 100).toFixed(2) }}
                            </span>
                        </td>
                      </tr>

                      <tr class="mb-1">
                        <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">Grand Total :</td>
                        <td class="py-0 pl-4 pr-0 " style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(orderPayment.total / 100).toFixed(2) }}</span>
                        </td>
                      </tr>
                      <tr class="mb-1">
                        <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">Paid :</td>
                        <td class="py-0 pl-4 pr-0" style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(orderPayment.paid / 100).toFixed(2) }}</span>
                        </td>
                      </tr>
                      <tr class="mb-1" v-if="orderPayment.remaining > 0">
                        <td class="py-0 pl-0 pr-0 text-right" style="width: 90%;color: #58595B;">Due :</td>
                        <td class="py-0 pl-4 pr-0" style="width: 100%">
                            <span class="font-weight-light" style="color: #58595B">
                              ${{ parseFloat(orderPayment.remaining / 100).toFixed(2) }}</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-end">
                <div class="col-xl-2 col-md-2 col-12 noPrint">
                  <div class="shadow-none">
                    <div class="py-2">
                      <button @click="print" class="btn btn-block text-white" style="background:#F38020">
                        <span>Print</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pt-4" v-if="orderNotes.length > 0">
                <div class="col-12">
                  <h5 class="font-weight-bold">Order Notes</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-12" v-for="(orderNote,index) in orderNotes" :key="index">
                  <p><span class="font-weight-bold">{{ (index + 1) }})</span> {{ orderNote.description }}</p>
                </div>
              </div>
            </div>

            <div class="bg-white">
              <img src="/app-assets/images/invoice/orderInvoice/orderInvoiceFooter.png" class="img-fluid" alt="order-invoice-footer">
            </div>
          </div>
          <!-- invoice action  -->
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import PrintElement from "@/components/backEnd/mixins/PrintElement";
import AppLayout from "@/layouts/backEnd/AppLayout"
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  name: "OrderInvoice",
  mixins: [ShowToastMessage, Loader, PrintElement],

  components: {
    AppLayout
  },
  data() {
    return {
      getOrderParams: {
        with_relation: [
          'user',
          'user.customer.address',
          'user.customer.address.business',
          'orderCharges',
          'orderPayments.payment',
          'orderPaymentsByReference',
          'appointment.technicianAppointment.technician.user',
          'shippingAddress.business',
          'billingAddress.business',
          'orderNotes',
          'orderProductItems.productItem.product',
          'orderProductItems.productItem.productItemTransaction',
        ],
      },
      orderPayment: {
        total: '',
        paid: '',
        remaining: '',
        chargesDetails: '',
      },
      subTotal: 0,
      customProducts: []
    }
  },
  watch: {
    orderDetails(currentValue) {
      currentValue.details = JSON.parse(currentValue.details)
      this.subTotal = currentValue.details.cart_data.reduce(function (acc, cur) {
        return acc + cur.product_price.sub_total
      }, 0);
      this.getOrderCalculation(currentValue)
    }
  },
  computed: {
    ...mapGetters({
      orderDetails: "appOrders/order"
    }),

    orderNotes() {
      return (this.orderDetails?.orderNotes ?? []).filter((singleNote) => singleNote.customer_can_view === 'Yes')
    },

    charges() {
      return {
        gst_charge: {
          name: 'GST Charge',
          sign: '+'
        },
        order_delivery_charge: {
          name: 'Delivery Charge',
          sign: '+'
        },
        applied_discount: {
          name: 'Applied Discount',
          sign: '-'
        },
        coupon_discount: {
          name: 'Coupon Discount',
          sign: '-'
        },
        credited_payment_discount: {
          name: 'Credited Discount',
          sign: '-'
        },
      };
    }
  },
  methods: {
    ...mapActions({
      getOrder: 'appOrders/getOrder',
      postOrderInvoice: 'appOrders/postOrderInvoice'

    }),

    fullAddress(customerObj) {
      let businessName = customerObj?.address?.business?.name ?? '';
      let addressStreet = customerObj?.address?.street;
      let addressSuburb = customerObj?.address?.suburb;
      let addressState = customerObj?.address?.state.toUpperCase();
      let addressPostCode = customerObj?.address?.post_code;
      let addressCountry = customerObj?.address?.country;
      return customerObj?.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    async postSingleOrderInvoice(uuid) {
      let paramObj = {
        uuid: uuid,
        params: this.getOrderParams,
      };
      await this.postOrderInvoice(paramObj).then(async (response) => {
        this.loader(false);
        if (response && response.status === 404) {
          await this.$router.push({name: '404'});
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    print() {
      let idElement = document.getElementById('printArea');
      this.printElement(idElement);
    },

    async getOrderCalculation(order) {
      let orderCharge = this.calculateOrderCharges(order);
      let orderPayment = this.calculateOrderPayments(order);
      let orderChargesDetails = this.getOrderChargesDetails(order);
      await this.generateProcessingProduct(order);

      let remaining = orderCharge - orderPayment;
      this.orderPayment = {
        total: orderCharge,
        paid: orderPayment,
        remaining: remaining,
        chargesDetails: orderChargesDetails
      };

      this.subTotal = order.status === 'Pending' ? order.details.cart_data.reduce(function (acc, cur) {
        return acc + cur.product_price.sub_total
      }, 0) : orderChargesDetails[0].amount;

    },

    calculateOrderCharges(singleOrder) {
      const decreaseType = ['Discount', 'Coupon']
      if (singleOrder.status === 'Pending') {
        return singleOrder?.details?.grand_total ?? 0;
      } else {
        return singleOrder.orderCharges.reduce(function (accu, cur) {
          accu = (decreaseType.includes(cur.type)) ? accu - cur.amount : accu + cur.amount
          return accu
        }, 0);
      }

    },

    calculateOrderPayments(singleOrder) {
      if (singleOrder.status === 'Pending') {
        return singleOrder.orderPaymentsByReference.reduce(function (acc, cur) {
          return acc + cur.total_transaction
        }, 0);
      } else {
        return singleOrder.orderPayments.reduce(function (accu, cur) {
          return (!('payment' in cur) && (!cur.payment)) ? 0 : accu + cur.payment.total_transaction
        }, 0);
      }

    },

    getOrderChargesDetails(singleOrder) {
      let orderItemsPrice = 0, orderGST = 0, orderDiscount = 0, orderSurCharge = 0, orderDeliveryCharge = 0,
          discountDetails = [];
      let appliedDiscount = 0, couponDiscount = 0, creditedPaymentDiscount = 0;


      if (singleOrder.status === 'Pending') {
        orderGST = singleOrder.details['gst_charge'].applied_status === true ? singleOrder.details['gst_charge'].amount : 0;
        orderDeliveryCharge = singleOrder.details['order_delivery_charge'].applied_status === true ? singleOrder.details['order_delivery_charge'].amount : 0;
        appliedDiscount = singleOrder.details['applied_discount'].applied_status === true ? singleOrder.details['applied_discount'].amount : 0;
        couponDiscount = singleOrder.details['coupon_discount'].applied_status === true ? singleOrder.details['coupon_discount'].amount : 0;
        creditedPaymentDiscount = singleOrder.details['credited_payment_discount'].applied_status === true ? singleOrder.details['credited_payment_discount'].amount : 0;
        orderDiscount = appliedDiscount + couponDiscount + creditedPaymentDiscount;
        orderItemsPrice = singleOrder.details['subtotal_amount'] ?? 0;
        orderSurCharge = singleOrder.details['surcharge'] ?? 0
        discountDetails = [
          {name: 'applied discount', amount: appliedDiscount},
          {name: 'coupon discount', amount: couponDiscount},
          {name: 'credited payment discount', amount: creditedPaymentDiscount},
        ]
      } else {
        singleOrder.orderCharges.forEach((charge) => {
          if (charge.type === 'GST') {
            orderGST = orderGST + charge?.amount ?? 0
          }
          if (charge.type === 'Surcharge') {
            orderSurCharge = orderSurCharge + charge?.amount ?? 0
          }
          if (charge.type === 'Items Price') {
            orderItemsPrice = orderItemsPrice + charge?.amount ?? 0
          }
          if (charge.type === 'Delivery Charge') {
            orderDeliveryCharge = orderDeliveryCharge + charge?.amount ?? 0
          }
          if (charge.type === 'Discount') {
            orderDiscount = orderDiscount + charge?.amount ?? 0
            discountDetails.unshift({name: charge.name, amount: charge.amount})
          }
        });
      }

      return [
        {name: 'items price', sign: '+', amount: orderItemsPrice},
        {name: 'surcharge', sign: '+', amount: orderSurCharge},
        {
          name: 'discount', sign: '-', amount: orderDiscount,
          details: discountDetails
        },
        {name: 'GST', sign: '+', amount: orderGST},
        {name: 'delivery charge', sign: '+', amount: orderDeliveryCharge},
      ]
    },
    async generateProcessingProduct(singleOrder) {
      if (singleOrder.status === 'Processing') {
        const productItemsSet = {};
        // Loop through each product item in the order
        this.orderDetails.orderProductItems.forEach(item => {
          const {id, name} = item.productItem.product;
          const productDetails = {
            condition: item.productItem.condition,
            serialNumber: item.productItem.serial_number,
            unitPrice: item.productItem.productItemTransaction?.sell_price
          };

          // If the product ID exists in the set, add the product details
          // Otherwise, create a new entry for the product ID
          if (!productItemsSet[id]) {
            productItemsSet[id] = {
              id: id,
              name: name,
              placed_date: item.placed_date,
              productItem: []
            };
          }
          productItemsSet[id].productItem.push(productDetails);
        });

        // Assign the organized set of product items to customProducts
        this.customProducts = productItemsSet;
      }
    },
  },
  async mounted() {

    this.loader(true);
    const routeParams = await this.$route.params;
    await this.postSingleOrderInvoice(routeParams.uuid);
    this.loader(false);
  },

}
</script>

<style scoped>
.table-text-color-background {
  border-right: 1px solid white;
  background: #F38020 !important;
}

@media print {
  .table-text-color-background {
    border-right: 1px solid white;
    background: #F38020 !important;
  }
}
</style>
